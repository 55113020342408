import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faThreads } from '@fortawesome/free-brands-svg-icons'

export default function Footer() {
    return (
        <footer>
            <ul class='social-icons'>
                <li><a href="https://www.instagram.com/petektsks/"><FontAwesomeIcon icon={faInstagram} /></a></li>
                <li><a href="https://www.tiktok.com/@petektsks"><FontAwesomeIcon icon={faTiktok} /></a></li>
                <li><a href="https://www.threads.net/@petektsks"><FontAwesomeIcon icon={faThreads} /></a></li>
            </ul>
            <p>© 2024 Pete Kitsakos All Rights Reserved.</p>
            <p>Powered by a creative with an engineering degree.</p>
        </footer>
    )
}