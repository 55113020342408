import ShopProduct from "../components/ShopProduct/ShopProduct";

export default function Shop() {
    const products = [
        {
            id: 1,
            productName: "Social Media Border Pack",
            price: "Free"
        },
    ];
    
    return (
        products.map(product => <ShopProduct key={product.id} product={product}/> )
    )
}