import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import { Outlet } from 'react-router'

export default function Root() {
    return (
        <div className='root'>
            <div className='page-content'>
                <header>
                    <Navbar></Navbar>
                </header>
                <body>
                    <Outlet />
                </body>
            </div>
            <Footer />
        </div>
    )
}