import './Navbar.css'
import { Link } from 'react-router-dom'

export default function Navbar() {
    return (
        <div className="navbar">
            <h1>Pete</h1>

            <ul>
                <li>
                    <Link to={'/'}>Home</Link>
                </li>
                <li>
                    <Link to={'shop'}>Shop</Link>
                </li>
            </ul>
        </div>
    )
}