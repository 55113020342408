import { Component } from 'react';
import './DownloadContent.css'
import axios from 'axios';

class DownloadContent extends Component {
    state = {
        email: '',
    };

    handleSubmit = event => {
        event.preventDefault();

        const email = this.state.email;

        axios.post('/sendEmail', { email })
            .then(res => {
                if (res.data.errors) {
                    return;
                }
                window.location = "/thankyou"
            })
            .catch(err => console.log(err.response ? err.response.data : err.message));
    }

    handleChange = event => {
        this.setState({ email: event.target.value })
    }

    render() {
        return (
        <div className="download-content">
            <img src={require('../../assets/borders_pack_product_photo.jpg')} />
            <div className="product-details">
                <h2>Social Media Border Pack</h2>
                <h3>Free</h3>
                <p>Elevate your video projects effortlessly with our Free Pack of Video Overlays! This pack includes video overlays for 3 different aspect ratios. Perfect for short form content these overlays are compatible with most editing software. Best of all, they’re completely free to download and use!</p>
                <form onSubmit={ this.handleSubmit }>
                    <input name="email" placeholder="example@petektsks.com" onChange={ this.handleChange } />
                    <button type="submit">Download</button>
                </form>
            </div>
        </div>
        )
    }
}

export default DownloadContent;