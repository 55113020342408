import './ShopProduct.css'
import { Link } from 'react-router-dom'

export default function ShopProduct({ product }) {
    return (
        <div>
            <Link to={'/downloads/1'} className='shop-product'>
                <h1>{product.productName}</h1>
                <img src={require('../../assets/borders_pack_product_photo150.jpg')} />
                <h2>{product.price}</h2>
            </Link>
        </div>
    )
}